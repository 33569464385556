<script setup lang='ts'>

const props = defineProps<{
    centreType: ExamStore['centreType']
    csId: ExamStore['csId']
    examSessionId: ExamStore['examSessionId']
    dualSubjectSessionId: ExamStore['dualSubjectSessionId']
    examDate: ExamStore['examDate'],
    scheduledEndTime: ExamStore['schedule']['end']
    hasExamExpired: ExamStore['hasExamExpired']
}>()

const isMissingCentreReportModalOpen = ref(false)
const isMissingSpecialRoomReportModalOpen = ref(false)
const isConfirmModalOpen = ref(false)
const isConfirmEditDisabled = ref(false)
const confirmationRemark = ref('')

const dayjs = useDayjs()
const { $cs, $getQueryKey } = useNuxtApp()

const toastStore = useToastStore()

const { mutate: confirmHall } = $cs.header.createHallConfirmExamStatus.useMutation()

const { data: status, refresh } = $cs.header.readHallConfirmExamStatus.useQuery({
    exam_session_id: props.examSessionId
})

const { data: hallDiscrepancies } = $cs.header.readHallDiscrepancy.useQuery({ exam_session_id: props.examSessionId })

// TODO re-consider whether it is necessary?
const { start: refreshConfirmation } = useAsyncInterval(() => refresh())

onMounted(refreshConfirmation)

async function handleClickPendingConfirmation() {
    /*
    const completion = await $cs.header.readSessionalReportsCompletion.query(
        { exam_session_id: props.examSessionId }
    )
    */
    await refreshNuxtData($getQueryKey(
        $cs.header.readHallDiscrepancy,
        { exam_session_id: props.examSessionId }
    ))
    /*
    if (completion.centre === false) {
        isMissingCentreReportModalOpen.value = true
    }
    else if (completion.specialRoom === false) {
        isMissingSpecialRoomReportModalOpen.value = true
    }
    else {
        isConfirmModalOpen.value = true
    }
    */
    const timer = await $cs.timer.readTimer.query({ exam_session_id: props.dualSubjectSessionId?.primary ?? props.examSessionId })
    const now = dayjs()
    const examEnded = props.scheduledEndTime && now.isAfter(dayjs(props.scheduledEndTime))
    const timerEndTime = dayjs(timer.started_time).add(timer.total_duration || 0, 'millisecond')
    const timerEnded = timer.started_time && !timer.paused_time && now.isAfter(timerEndTime)

    if (!examEnded && !timerEnded) {
        return toastStore.push({
            key: 'timer-not-ended',
            type: 'error',
            message: { key: 'toast.error.exam_not_ended' }
        })
    }

    isConfirmModalOpen.value = true
}

function handleCancelMissingCentreReport() {
    isMissingCentreReportModalOpen.value = false
}

function handleCancelMissingSpecialRoomReport() {
    isMissingSpecialRoomReportModalOpen.value = false
}

function handleCloseEditDialog() {
    isConfirmModalOpen.value = false
}

async function handleConfirmExam() {
    if (props.examSessionId) {
        await confirmHall({
            creator_id: props.csId,
            exam_session_id: props.examSessionId,
            remark: hallDiscrepancies.value ? confirmationRemark.value : ''
        })
        isConfirmModalOpen.value = false
        await refresh()
    }
}

function handleInputConfirmationRemark(event: Event) {
    confirmationRemark.value = (event.target as HTMLInputElement).value
}

</script>

<template>
    <div
        v-if='props.centreType !== "CLASSROOM"'
        class='my-auto'
    >
        <button
            class='flex flex-col items-center p-2 font-medium disabled:hover:bg-gray-100'
            :class='{
                "text-red-800": status?.confirmed === true,
                "text-blue-800": status?.confirmed !== true,
                "cursor-not-allowed": status?.confirmed === true || hasExamExpired || isAfterToday(examDate),
            }'
            :disabled='status?.confirmed === true || hasExamExpired || isAfterToday(examDate)'
            @click='handleClickPendingConfirmation()'
        >
            <div>
                <UploadIcon />
            </div>
            <div v-if='status?.confirmed === true'>
                {{ $t('confirm_status.confirmed') }}
            </div>
            <div v-else>
                {{ $t('confirm_status.pending_to_confirm') }}
            </div>
            <div v-if='status?.confirmed === true'>
                {{ $dayjs(status?.confirmed_at).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
        </button>

        <ConfirmationDialogue
            cancel-button-id='cancel-pending-sessional-report'
            :show='isMissingCentreReportModalOpen'
            :header='$t("confirm_status.missing_sessional_report")'
            :can-confirm='false'
            @cancel='handleCancelMissingCentreReport'
            @close='isMissingCentreReportModalOpen = false'
        >
            <p class='mb-4 text-red-700'>
                {{ $t('confirm_status.sessional_report.missing') }}
            </p>

            <p class='text-red-700'>
                {{ $t('confirm_status.sessional_report.missing_reminder') }}
            </p>
        </ConfirmationDialogue>

        <ConfirmationDialogue
            cancel-button-id='cancel-pending-sessional-report-in-room'
            :show='isMissingSpecialRoomReportModalOpen'
            :header='$t("confirm_status.missing_sessional_report_sr")'
            :can-confirm='false'
            @cancel='handleCancelMissingSpecialRoomReport'
            @close='isMissingSpecialRoomReportModalOpen = false'
        >
            <p class='text-red-700'>
                {{ $t('confirm_status.sessional_report.pending_by_sri_hall') }}
            </p>
        </ConfirmationDialogue>

        <ConfirmationDialogue
            cancel-button-id='cancel-confirm-exam-with-remark'
            confirm-button-id='confirm-confirm-exam-with-remark'
            :show='!!hallDiscrepancies && isConfirmModalOpen'
            :header='$t("confirm_status.discrepancy_records_not_yet_cleared")'
            :disable-confirm='isConfirmEditDisabled || !confirmationRemark'
            @cancel='handleCloseEditDialog'
            @close='isConfirmModalOpen = false'
            @confirm='handleConfirmExam'
        >
            <p>{{ $t('confirm_status.discrepancy_records_not_yet_cleared_reminder') }}</p>

            <textarea
                name='remark'
                :value='confirmationRemark'
                :placeholder='$t("confirm_status.enter_confirmation_remark")'
                rows='5'
                class='m-0 mt-4 block w-full appearance-none rounded border border-solid border-gray-300 bg-white bg-clip-padding bg-no-repeat px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none'
                @input='handleInputConfirmationRemark'
            />
        </ConfirmationDialogue>

        <ConfirmationDialogue
            cancel-button-id='cancel-confirm-exam'
            confirm-button-id='confirm-confirm-exam'
            :show='!hallDiscrepancies && isConfirmModalOpen'
            :header='$t("confirm_status.pending_to_confirm")'
            @cancel='handleCloseEditDialog'
            @close='isConfirmModalOpen = false'
            @confirm='handleConfirmExam'
        >
            <div>
                <span>{{ $t('confirm_status.confirm_to_vcc') }}</span>
            </div>
        </ConfirmationDialogue>
    </div>
</template>
