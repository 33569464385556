export function isAfterToday(dateString?: string | null) {
    if (!dateString) {
        return true
    }

    const date = new Date(dateString)

    if (Object.prototype.toString.call(date) !== '[object Date]' || isNaN(date.getTime())) {
        return true
    }

    const today = new Date()

    date.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    return today < date
}
