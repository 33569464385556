export type ToastType = 'info' | 'warn' | 'error'

export type ToastI18nInput = {
    key: string
    params?: Array<Array<string | ToastI18nInput>>
}

export type Toast<T extends ToastType> = {
    key: string
    message: string | ToastI18nInput | Array<string | ToastI18nInput>
    type: T
    /** Time to live in seconds */
    ttl?: number
    shouldBlock?: T extends 'info' ? never : boolean
}

type ToastStore = {
    count: number
    nonBlockingCount: number
    shouldShow: boolean
    shouldBlock: boolean
    toast: Record<Toast<ToastType>['key'], Toast<ToastType>>
    timer: Record<Toast<ToastType>['key'], NodeJS.Timeout>
}

export const useToastStore = defineStore('toast', {
    state: (): ToastStore => ({
        count: 0,
        nonBlockingCount: 0,
        shouldShow: true,
        shouldBlock: false,
        toast: {},
        timer: {}
    }),
    actions: {
        remove(key: Toast<ToastType>['key']) {
            if (!this.shouldShow) {
                return
            }

            clearTimeout(this.timer[key])

            if (this.count === 1) {
                this.shouldShow = false
                this.shouldBlock = false

                setTimeout(() => {
                    delete this.toast[key]
                    delete this.timer[key]
                    this.count = 0
                    this.nonBlockingCount = 0
                }, 1000)
            }
            else {
                if (this.toast[key].type === 'info' || this.toast[key].shouldBlock === false) {
                    this.nonBlockingCount--
                }

                delete this.toast[key]
                delete this.timer[key]
                this.count--
                this.shouldShow = !!this.count
                this.shouldBlock = this.count !== this.nonBlockingCount
            }
        },
        push(payload: Toast<ToastType> | Array<Toast<ToastType>>) {
            if (!this.count && !this.shouldShow) {
                this.shouldShow = true
            }

            if (Array.isArray(payload)) {
                for (const toast of payload) {
                    this.pushOne(toast)
                }
            }
            else {
                this.pushOne(payload)
            }

            this.shouldBlock = this.count !== this.nonBlockingCount
        },
        /** ⛔ For store internal use only ⛔ */
        pushOne(payload: Toast<ToastType>) {
            if (this.toast[payload.key]) {
                return
            }

            if (payload.type === 'info') {
                this.nonBlockingCount++
                this.timer[payload.key] = setTimeout(() => this.remove(payload.key), (payload.ttl || 5) * 1000)
            }
            else {
                if (payload.shouldBlock === false) {
                    this.nonBlockingCount++
                }

                if (payload.ttl) {
                    this.timer[payload.key] = setTimeout(() => this.remove(payload.key), payload.ttl * 1000)
                }
            }

            this.count++
            this.toast[payload.key] = payload
        }
    }
})
