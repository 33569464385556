export function useServerErrorHandler(translateError: (message: string) => string) {
    const loginStore = useLoginStore()
    const toastStore = useToastStore()

    return {
        handleServerError(error: unknown) {
            if (!(error instanceof Error)) {
                return
            }

            const { message } = error

            if (message === 'attendance_force_logout_by_other_cs_dcs' || message === 'jwt expired') {
                const shouldPrompt = !!loginStore.accessToken

                loginStore.$reset()

                clearError({ redirect: '/login' }).then(() => {
                    if (!shouldPrompt) {
                        return
                    }

                    toastStore.push({
                        key: message,
                        message: translateError(`toast.error.${message.replace(/\s+/g, '_')}`),
                        type: 'warn'
                    })
                })
            }
        }
    }
}
