<script setup lang='ts'>

const props = defineProps<{
    examSessionId: ExamStore['examSessionId']
    centreType: ExamStore['centreType']
    supervisorId: ExamStore['csId']
    epNo: ExamStore['epNo']
    post: ExamStore['post']
    formOfExam: ExamStore['formOfExam']
}>()

const { $cs } = useNuxtApp()

const toastStore = useToastStore()
const loginStore = useLoginStore()
const examStore = useExamStore()

const showReminder = ref(false)

const { mutate: createCsLogout } = $cs.auth.createCsLogout.useMutation()

async function logout() {
    const logoutSuccess = await createCsLogout({
        exam_session_id: props.examSessionId,
        invigilator_id: props.supervisorId
    })

    if (!logoutSuccess) {
        return toastStore.push({
            key: 'failed-to-logout',
            type: 'error',
            message: { key: 'toast.fail.logout' }
        })
    }

    loginStore.$reset()
    toastStore.$reset()

    await navigateTo('/login')

    toastStore.push({
        key: 'logout-success',
        type: 'info',
        message: { key: 'toast.success.logout' }
    })

    clearNuxtData()
    examStore.$reset()
}

async function handleLogout() {
    if (!props.examSessionId) {
        return
    }

    if (props.formOfExam.includes('MB')) {
        return logout()
    }

    const status = await $cs.header.readHallConfirmExamStatus.query({
        exam_session_id: props.examSessionId
    })
    if (status?.confirmed) {
        return logout()
    }
    showReminder.value = true
}

</script>

<template>
    <div
        class='grid cursor-pointer place-content-center p-2 font-medium text-blue-800'
        @click='handleLogout'
    >
        <span class='text-center'>
            {{ props.post }}
        </span>

        <span class='text-center text-sm font-semibold'>
            {{ props.epNo }}
        </span>

        <div class='grid grid-flow-col items-center gap-1'>
            {{ $t('menu.logout') }}
            <LogoutIcon />
        </div>
    </div>

    <ConfirmationDialogue
        cancel-button-id='cancel-logout'
        confirm-button-id='confirm-logout'
        :show='showReminder'
        :header='$t("general_terms.reminder")'
        :message='$t(`confirm_status.logout_without_confirmation_reminder_${centreType === "CLASSROOM" ? "classroom" : "hall"}`)'
        @cancel='showReminder = false'
        @close='showReminder = false'
        @confirm='showReminder = false; logout()'
    />
</template>
