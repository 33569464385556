<script setup lang='ts'>
import type { TimerStatus } from './timer.types'

const { t } = useI18n()
const { $cs } = useNuxtApp()
const dayjs = useDayjs()

type Props = {
    examSessionId: ExamStore['examSessionId']
    dualSubjectSessionId: ExamStore['dualSubjectSessionId']
    schedule: ExamStore['schedule']
    examDate: ExamStore['examDate']
    supervisorId: ExamStore['csId']
    paperCode: ExamStore['paperCode']
}

const toastStore = useToastStore()

const props = defineProps<Props>()

const isLoaded = ref(false)

const startedTime = ref<string|null>(null)
const pausedTime = ref<string|null>(null)
const remainingDurationInSec = ref(0)
const totalDurationInMs = ref(0)
const serverTimeOffsetInMs = ref<number>(0)

const timerReminderTitle = ref('title')
const timerReminderMessage = ref('message')

const timerInterval = ref<InstanceType<typeof AnimationFrameInterval> | null>(null)

const isTimerReminderShown = ref(false)
const timerResetConfirmation = ref(false)

const timeReminder = reactive({
    is15MinsLeft: false,
    is5MinsLeft: false,
    isTimeUp: false
})

const updateTimeReminder = (remaining: number) => {
    timeReminder.is15MinsLeft = remaining <= 15 * 60
    timeReminder.is5MinsLeft = remaining <= 5 * 60
    timeReminder.isTimeUp = remaining <= 0
}

const { mutate: start, error: startError } = $cs.timer.startTimer.useMutation()
const { mutate: reset } = $cs.timer.resetTimer.useMutation()
const { mutate: pause } = $cs.timer.pauseTimer.useMutation()
const { mutate: resume } = $cs.timer.resumeTimer.useMutation()

const status = computed<TimerStatus>(() => {
    if (!totalDurationInMs.value) {
        return 'NOT_SET'
    }

    if (!startedTime.value) {
        return 'NOT_STARTED'
    }

    if (pausedTime.value) {
        return 'PAUSED'
    }

    if (remainingDurationInSec.value <= 0) {
        return 'ENDED'
    }

    return 'STARTED'
})

const scheduledDurationInSec = computed(() => {
    if (status.value !== 'NOT_SET') {
        return totalDurationInMs.value / 1000
    }

    if (props.paperCode === '3') {
        return 75 * 60
    }

    return dayjs(props.schedule.end).diff(props.schedule.start, 'second')
})

onMounted(async () => {
    await handleReload()
})

watch(status, newStatus => {
    if (newStatus === 'STARTED') {
        startTimerCounting()
    }
})

const updateRemainingDuration = () => {
    const referenceTime = pausedTime.value ? +new Date(pausedTime.value) : (Date.now() - serverTimeOffsetInMs.value)
    remainingDurationInSec.value = getRemainingDurationInSec(startedTime.value, totalDurationInMs.value, referenceTime)
}

const resetTimerValues = () => {
    startedTime.value = null
    pausedTime.value = null
    totalDurationInMs.value = 0
    remainingDurationInSec.value = 0
}

const handleReload = async () => {
    isLoaded.value = false

    const result = await $cs.timer.readTimer.query({ exam_session_id: props.dualSubjectSessionId?.primary ?? props.examSessionId })

    if (result) {
        startedTime.value = result.started_time
        pausedTime.value = result.paused_time
        totalDurationInMs.value = result.total_duration ?? 0
        serverTimeOffsetInMs.value = result.server_time_ms ? Date.now() - result.server_time_ms : 0
        updateRemainingDuration()
    }
    else {
        resetTimerValues()
    }

    isLoaded.value = true
}

const handleUpdateSettings = (durationInMs: number) => {
    totalDurationInMs.value = durationInMs
    updateRemainingDuration()
    updateTimeReminder(remainingDurationInSec.value)
}

const pushExamNotStartedToastMessage = () => {
    toastStore.push({
        key: 'exam-not-started',
        type: 'error',
        message: { key: 'timer.exam_not_started' }
    })
}

const handleStart = async () => {
    if (dayjs().isBefore(dayjs(props.schedule.start))) {
        return pushExamNotStartedToastMessage()
    }

    const result = await start({
        exam_session_id: props.examSessionId,
        updated_by: props.supervisorId
    })

    if (result) {
        startedTime.value = result.started_time
        totalDurationInMs.value = result.total_duration
        remainingDurationInSec.value = result.total_duration / 1000
        serverTimeOffsetInMs.value = Date.now() - result.server_time_ms
    }
    else {
        if (startError.value?.message === 'exam_not_started') {
            return pushExamNotStartedToastMessage()
        }

        await handleReload()
    }
}

const handleReset = async () => {
    const result = await reset({
        exam_session_id: props.examSessionId,
        updated_by: props.supervisorId
    })

    if (result) {
        resetTimerValues()
        if (timerInterval.value) {
            timerInterval.value.stop()
            timerInterval.value = null
        }
    }
    else {
        await handleReload()
    }
}

const handlePause = async () => {
    const result = await pause({
        exam_session_id: props.examSessionId,
        updated_by: props.supervisorId
    })

    if (result) {
        pausedTime.value = result.paused_time

        totalDurationInMs.value = result.total_duration
    }
    else {
        await handleReload()
    }
}

const handleResume = async () => {
    const result = await resume({
        exam_session_id: props.examSessionId,
        updated_by: props.supervisorId
    })

    if (result) {
        pausedTime.value = result.paused_time

        totalDurationInMs.value = result.total_duration
    }
    else {
        await handleReload()
    }
}

const handleConfirmReset = async () => {
    await handleReset()
    timerResetConfirmation.value = false
}

const countDown = () => {
    updateRemainingDuration()

    if (!timeReminder.isTimeUp && remainingDurationInSec.value <= 0 && status.value !== 'NOT_SET') {
        timerReminderTitle.value = t('general_terms.reminder')
        timerReminderMessage.value = t('toast.reminders.exam_ended')
        isTimerReminderShown.value = true

        updateTimeReminder(remainingDurationInSec.value)
        timerInterval.value = null

        return false
    }
    else if (!timeReminder.is5MinsLeft && remainingDurationInSec.value <= 5 * 60) {
        timerReminderTitle.value = t('toast.reminders.time_left')
        timerReminderMessage.value = t('toast.reminders.time_left_message', [5])
        isTimerReminderShown.value = true

        updateTimeReminder(remainingDurationInSec.value)
    }
    else if (!timeReminder.is15MinsLeft && remainingDurationInSec.value <= 15 * 60) {
        timerReminderTitle.value = t('toast.reminders.time_left')
        timerReminderMessage.value = t('toast.reminders.time_left_message', [15])
        isTimerReminderShown.value = true

        updateTimeReminder(remainingDurationInSec.value)
    }

    return true
}

const startTimerCounting = () => {
    updateTimeReminder(remainingDurationInSec.value)

    if (!timerInterval.value) {
        timerInterval.value = new AnimationFrameInterval(countDown)
        timerInterval.value.start()
    }
}

</script>

<template>
    <div class='flex flex-1 rounded-lg bg-white text-blue-900 2xl:text-lg'>
        <div
            class='flex items-center justify-center rounded-l-lg bg-gray-700 text-xl
              font-bold text-white'
        >
            <div class='flex flex-col items-center px-6'>
                <TimeIcon />
                <div>{{ $t('timer.timer') }}</div>
            </div>
        </div>
        <div v-if='!isLoaded' class='m-auto grid grid-cols-3 place-items-center gap-x-4 gap-y-8 p-4 xl:gap-x-10'>
            <ShadcnSkeleton class='col-span-3 h-10 w-full' />
            <ShadcnSkeleton class='h-[128px] w-[112px]' />
            <ShadcnSkeleton class='h-[128px] w-[112px]' />
            <ShadcnSkeleton class='h-[128px] w-[112px]' />
            <ShadcnSkeleton class='col-span-3 h-10 w-1/2' />
        </div>
        <div
            v-else
            class='flex w-full flex-auto flex-col items-center gap-y-2 p-4'
        >
            <TimerSettings
                :exam-session-id='examSessionId'
                :status='status'
                :duration-in-sec='scheduledDurationInSec'
                :supervisor-id='supervisorId'
                :exam-date='examDate'
                :view-only='dualSubjectSessionId?.secondary === examSessionId'
                @update='handleUpdateSettings'
            />
            <TimerClock
                class='flex-1'
                :duration='remainingDurationInSec'
            />
            <div
                v-if='dualSubjectSessionId?.secondary !== examSessionId'
                class='flex gap-x-2'
            >
                <div>
                    <GreenSolidButton
                        v-if='status === "NOT_STARTED"'
                        @click='handleStart'
                    >
                        {{ $t('timer.start') }}
                    </GreenSolidButton>
                    <DarkSolidButton
                        v-else-if='status === "STARTED"'
                        @click='handlePause'
                    >
                        {{ $t('timer.pause') }}
                    </DarkSolidButton>
                    <GreenSolidButton
                        v-else-if='status === "PAUSED"'
                        @click='handleResume'
                    >
                        {{ $t('timer.continue') }}
                    </GreenSolidButton>
                </div>
                <DarkSolidButton
                    v-if='status !== "NOT_SET" && status !== "ENDED"'
                    @click='timerResetConfirmation = true'
                >
                    {{ $t('timer.reset') }}
                </DarkSolidButton>
            </div>

            <div
                v-if='status === "ENDED"'
                class='w-full border-t py-2 text-center font-bold tracking-widest'
            >
                {{ $t('timer.exam_ended') }}
            </div>
        </div>

        <ConfirmationDialogue
            confirm-button-id='confirm-timer-message'
            confirm-text='ok'
            :show='isTimerReminderShown'
            :header='timerReminderTitle'
            :message='timerReminderMessage'
            @confirm='isTimerReminderShown = false'
            @close='isTimerReminderShown = false'
        />

        <ConfirmationDialogue
            cancel-button-id='cancel-reset-timer'
            confirm-button-id='confirm-reset-timer'
            :show='timerResetConfirmation'
            :header='$t("timer.reset_timer")'
            :message='$t("timer.reset_timer_confirm")'
            @cancel='timerResetConfirmation = false'
            @close='timerResetConfirmation = false'
            @confirm='handleConfirmReset'
        />
    </div>
</template>
