import revive_payload_client_2pa6ObmhT9 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_REYqX7ZwVa from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fVucLEk3fE from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_70N2oTMooW from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RExHP9g3o5 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HY2TpaWdSH from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_b8QwPK9q0P from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_1PJmVF2NqC from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_drizzle-orm@0.33.0_@types+pg@8.11.10_pg@_u3su25ckwwy3joko2pk62gvrrm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_liyViRA2ax from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_7EqEeZp25v from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.1.0_patch_hash=y2bjtiada6cj3qvvllbuctyaka_@babel+parser@7.25.8_magica_szgdeu6dg4l7zhiit3jni57vfy/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_EbELCf3CCh from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0GX2wNXaPc from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_rS5xb60Z0v from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_n7tokq7p5orfvfqa7nvbtjgnzi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_2pa6ObmhT9,
  unhead_REYqX7ZwVa,
  router_fVucLEk3fE,
  payload_client_70N2oTMooW,
  navigation_repaint_client_RExHP9g3o5,
  check_outdated_build_client_HY2TpaWdSH,
  chunk_reload_client_b8QwPK9q0P,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1PJmVF2NqC,
  plugin_liyViRA2ax,
  primevue_plugin_egKpok8Auk,
  plugin_client_7EqEeZp25v,
  switch_locale_path_ssr_EbELCf3CCh,
  i18n_0GX2wNXaPc,
  plugin_rS5xb60Z0v,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]