<script setup lang="ts">

import type { TimerStatus } from './timer.types'

const { $cs } = useNuxtApp()
const toastStore = useToastStore()

const props = defineProps<{
    examSessionId: ExamStore['examSessionId']
    status: TimerStatus,
    durationInSec: number,
    supervisorId: ExamStore['csId']
    examDate: ExamStore['examDate']
    viewOnly: boolean
}>()

const emit = defineEmits<{
    update: [duration: number],
}>()

const { mutate: create } = $cs.timer.createTimer.useMutation()
const { mutate: extend } = $cs.timer.extendTimer.useMutation()

const hour = ref<string>('00')
const minute = ref<string>('00')
const second = ref<string>('00')
const extendedMinutes = ref<string>('00')
const lastKeyStroke = ref<string>('')

const toggleAddMinutes = ref(false)

onMounted(() => {
    setDuration(props.durationInSec)
})

watch(() => props.durationInSec, value => {
    setDuration(value)
})

const durationInMs = computed(() => {
    return (parseInt(hour.value) * 3600 + parseInt(minute.value) * 60 + parseInt(second.value)) * 1000
})

const sanitiseInput = (value: number, min: number, max: number) => {
    const str = value.toString().replace(/\D/g, '').slice(-2)

    if (value > 100) {
        return lastKeyStroke.value.padStart(2, '0')
    }

    if (value < min) {
        return min.toString().padStart(2, '0')
    }

    if (value > max) {
        return max.toString().padStart(2, '0')
    }

    return str.padStart(2, '0')
}

const setDuration = (durationInSec: number) => {
    const h = Math.floor(durationInSec / 3600)
    const m = Math.floor((durationInSec % 3600) / 60)
    const s = Math.floor(durationInSec % 60)

    hour.value = h.toString().padStart(2, '0')
    minute.value = m.toString().padStart(2, '0')
    second.value = s.toString().padStart(2, '0')
}

const handleUpdateHour = (value: number) => {
    hour.value = sanitiseInput(value, 0, 23)
}

const handleUpdateMinute = (value: number) => {
    minute.value = sanitiseInput(value, 0, 59)
}

const handleUpdateSecond = (value: number) => {
    second.value = sanitiseInput(value, 0, 59)
}

const handleUpdateExtendedMinutes = (value: number) => {
    extendedMinutes.value = sanitiseInput(value, 0, 59)
}

const handleCreate = async () => {
    const result = await create({
        exam_session_id: props.examSessionId,
        duration: durationInMs.value,
        created_by: props.supervisorId
    })

    if (result) {
        toastStore.push({
            key: 'set_timer_success',
            type: 'info',
            message: { key: 'toast.success.set_timer' }
        })
        emit('update', result.total_duration)
    }
    else {
        toastStore.push({
            key: 'set_timer_error',
            type: 'error',
            message: { key: 'toast.error.set_timer' }
        })
    }
}

const handleExtend = async () => {
    const result = await extend({
        exam_session_id: props.examSessionId,
        extra_duration: parseInt(extendedMinutes.value) * 60 * 1000,
        updated_by: props.supervisorId
    })

    if (result) {
        toastStore.push({
            key: 'extend_timer_success',
            type: 'info',
            message: { key: 'toast.success.extend_timer' }
        })
        emit('update', result.total_duration)
    }
    else {
        toastStore.push({
            key: 'extend_timer_error',
            type: 'error',
            message: { key: 'toast.error.extend_timer' }
        })
    }

    toggleAddMinutes.value = false
    extendedMinutes.value = '00'
}

const handleKeyDown = (key: string) => {
    if (key.match(/[0-9]/)) {
        lastKeyStroke.value = key
    }
}

</script>

<template>
    <div class='grid grid-cols-[repeat(6,min-content)] font-medium'>
        <span class='col-span-6 text-sm'>
            {{ $t('timer.settings') }}
        </span>

        <StyledInput
            class='text-center [&::-webkit-inner-spin-button]:opacity-100 [&]:w-20'
            type='number'
            name='timer-hour'
            :pad-start='[2, "0"]'
            :value='Number(hour)'
            :attributes='{
                min: 0,
                max: 23,
                disabled: viewOnly || status !== "NOT_SET" || isAfterToday(props.examDate)
            }'
            @input='handleUpdateHour'
            @keydown='handleKeyDown'
        />

        <span class='px-2 text-3xl'>:</span>

        <StyledInput
            class='text-center [&::-webkit-inner-spin-button]:opacity-100 [&]:w-20'
            type='number'
            name='timer-minute'
            :pad-start='[2, "0"]'
            :value='Number(minute)'
            :attributes='{
                min: 0,
                max: 59,
                disabled: viewOnly || status !== "NOT_SET" || isAfterToday(props.examDate)
            }'
            @input='handleUpdateMinute'
            @keydown='handleKeyDown'
        />

        <span class='px-2 text-3xl'>:</span>

        <StyledInput
            class='text-center [&::-webkit-inner-spin-button]:opacity-100 [&]:w-20'
            type='number'
            name='timer-second'
            :pad-start='[2, "0"]'
            :value='Number(second)'
            :attributes='{
                min: 0,
                max: 59,
                disabled: viewOnly || status !== "NOT_SET" || isAfterToday(props.examDate)
            }'
            @input='handleUpdateSecond'
            @keydown='handleKeyDown'
        />

        <span v-if='viewOnly' />
        <GreenSolidButton
            v-else-if='status === "NOT_SET"'
            class='ml-4 whitespace-nowrap'
            :disabled='durationInMs <= 0 || isAfterToday(props.examDate)'
            @click='handleCreate'
        >
            {{ $t('timer.set_timer') }}
        </GreenSolidButton>
        <div
            v-else-if='status !== "ENDED"'
            class='relative'
        >
            <BlueSolidButton
                class='ml-4 whitespace-nowrap'
                @click='toggleAddMinutes = !toggleAddMinutes'
            >
                {{ $t('timer.add_minutes') }}
            </BlueSolidButton>

            <template v-if='toggleAddMinutes'>
                <div
                    class='fixed inset-0 z-10'
                    @click='toggleAddMinutes = false'
                />
                <div class='absolute right-0 z-50 grid w-56 translate-y-2 grid-cols-[repeat(2,min-content)] gap-x-2 rounded-lg border-4 bg-white p-2 shadow-md'>
                    <StyledInput
                        class='text-center [&::-webkit-inner-spin-button]:opacity-100 [&]:w-20'
                        type='number'
                        name='timer-extension'
                        :pad-start='[2, "0"]'
                        :value='Number(extendedMinutes)'
                        :attributes='{
                            min: 0,
                            max: 59,
                            disabled: isAfterToday(examDate)
                        }'
                        @input='handleUpdateExtendedMinutes'
                        @keydown='handleKeyDown'
                    />
                    <GreenSolidButton
                        class='scale-90 whitespace-nowrap'
                        :disabled='extendedMinutes == "00" || isAfterToday(props.examDate)'
                        @click='handleExtend'
                    >
                        {{ $t('general_terms.confirm') }}
                    </GreenSolidButton>
                    <span class='text-center text-sm'>mm</span>
                </div>
            </template>
        </div>

        <span class='col-start-1 text-center text-sm'>hh</span>
        <span class='col-start-3 text-center text-sm'>mm</span>
        <span class='col-start-5 text-center text-sm'>ss</span>
    </div>
</template>
