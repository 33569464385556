<script setup lang='ts'>

defineProps<{
    centreType: ExamStore['centreType']
    examSessionId: ExamStore['examSessionId']
    examDate: ExamStore['examDate']
}>()

const menuOpen = ref(false)
</script>

<template>
    <div class='relative my-auto h-full'>
        <div
            v-if='menuOpen'
            class='fixed inset-0 z-50'
            @click='menuOpen = false'
        />
        <button
            class='relative flex h-full items-center p-2 font-medium hover:bg-green-700 hover:text-white disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:bg-gray-200'
            :class='{"bg-green-700 text-white": menuOpen, "text-blue-800": !menuOpen}'
            :disabled='isAfterToday(examDate)'
            @click='menuOpen = !menuOpen'
        >
            <div class='flex flex-col items-center'>
                <ReportSmallIcon />
                <div class='flex flex-row items-center'>
                    {{ $t('menu.report') }}
                    <svg
                        class='ml-1 size-5 fill-current'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                    >
                        <path
                            d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1
    1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'
                        />
                    </svg>
                </div>
            </div>
        </button>
        <transition
            enter-active-class='transition-all transition-fastest ease-out-quad'
            leave-active-class='transition-all transition-faster ease-in-quad'
            enter-class='opacity-0 scale-70'
            enter-to-class='opacity-100 scale-100'
            leave-class='opacity-100 scale-100'
            leave-to-class='opacity-0 scale-70'
        >
            <div
                v-if='menuOpen'
                class='absolute right-0 z-50 rounded-lg border bg-green-700 px-3 py-2 font-medium text-white shadow-xl'
            >
                <ul>
                    <!-- <li>
                        <router-link
                            class='block w-48 rounded-md px-4 py-2 hover:bg-green-900 hover:text-white'
                            to='/sessionalReports'
                            @click='menuOpen = false'
                        >
                            {{ $t('routes.sessional_report') }}
                        </router-link>
                    </li> -->
                    <li>
                        <router-link
                            class='block w-48 rounded-md px-4 py-2 hover:bg-green-900 hover:text-white'
                            to='/discrepancyReports'
                            @click='menuOpen = false'
                        >
                            {{ $t('routes.discrepancy_report') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class='block w-48 rounded-md px-4 py-2 hover:bg-green-900 hover:text-white'
                            to='/centreSummaryReport'
                            @click='menuOpen = false'
                        >
                            {{ $t('routes.summary_report') }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>
